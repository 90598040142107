<template lang="pug">
div
  // TODO: Figure out how to do `:attach="$el"` in Vue 3.
  VMenu.index-study-menu
    template(#activator="activatorProps")
      VBtn.text-black.ml-2(
        v-bind="activatorProps['props']"
        color="secondary-c500"
        :disabled="!commentaryTypeCounts.length"
        variant="flat"
      ) {{ i18n.t("Global.study") }}
    VList
      VListItem(
        v-for="{ commentaryType, count } in commentaryTypeCounts"
        :key="commentaryType.shortName"
        :to="localePath(getCommentaryLocationFromRange(commentaryType.shortName, range))"
      )
        template(#prepend)
          CommentaryTypeIcon(
            :short-name="commentaryType.shortName"
            variant="outline"
          )
        VListItemTitle {{ commentaryType.name[locale] }}
        template(#append)
          VBadge(
            :content="count"
            :inline="true"
          )
</template>

<script setup lang="ts">
import { type TranslationAgnosticVidRange } from "@rsc/scripture-util";
import { useI18n } from "vue-i18n";
import { type CommentaryIndexCommentaryTypeCount } from "~/units/commentary/commentaryIndex";
import { getCommentaryLocationFromRange } from "~/routing/locations";

const i18n = useI18n();
const locale = i18n.locale;
const localePath = useLocalePath();

interface Props {
  range: TranslationAgnosticVidRange;
  commentaryTypeCounts?: CommentaryIndexCommentaryTypeCount[];
}

const props = withDefaults(defineProps<Props>(), {
  commentaryTypeCounts: () => [],
});
</script>
